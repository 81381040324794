<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules" #default="{ errors }">
    <b-form-datepicker
      v-bind="$attrs"
      v-model="innerValue"
      local="ru-RU"
      class="mb-2"
      today-variant="danger"
      hide-header
      start-weekday="1"
      :state="errors.length > 0 ? false : null"
      label-no-date-selected=""
      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
    />
    <!-- <field-error-text>{{ errors[0] }}</field-error-text> -->
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import FieldErrorText from '@/components/inputs/FieldErrorText.vue';

export default {
  name: 'DateWithValidation',
  props: {
    rules: {
      type: String,
      default: '',
    },
    value: {
      type: null,
    },
  },
  components: {
    ValidationProvider,
    FieldErrorText,
  },
  data: () => ({
    innerValue: null,
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) this.innerValue = this.value;
  },
};
</script>

<style></style>
