<template>
  <b-modal
    id="modal-transaction"
    ref="transaction-modal"
    :title="title"
    :ok-title="okTitle"
    cancel-variant="outline-secondary"
    cancel-title="Отмена"
    @ok="handleOk"
    button-size="sm"
    no-close-on-backdrop
    size="sm"
  >
    <validation-observer ref="tranFormRef">
      <b-form @submit.stop.prevent="handleSubmit">
        <transaction-form ref="tranDataRef" />
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { VBModal } from "bootstrap-vue";
import { ref, computed, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { ValidationObserver } from "vee-validate";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import store from "@/store";

import TransactionForm from "@/components/investments/transactions/TransactionForm.vue";
import usePortfolio from "@/comp-functions/usePortfolio";

export default {
  name: "TransactionModal",
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    ValidationObserver,
    TransactionForm,
  },
  setup(props, contex) {
    const toast = useToast();

    const { currentPortfolio } = usePortfolio();
    const currentTransaction = computed(() => store.getters["investments/curTransaction"]);
    const isNew = computed(() => !currentTransaction.value);

    const title = ref("Новая сделка");
    const okTitle = ref("Добавить");

    watch(isNew, () => {
      if (isNew.value) {
        title.value = "Новая сделка";
        okTitle.value = "Добавить";
      } else {
        title.value = "Изменить сделку";
        okTitle.value = "Изменить";
      }
    });
    // Form
    const checkFormValidity = () => contex.refs.tranFormRef.validate();
    const saveTransaction = async (dataForm) => {
      if (isNew.value) {
        await store.dispatch("investments/createTransaction", dataForm);
      } else {
        await store.dispatch("investments/updateTransaction", dataForm);
      }
    };
    const handleSubmit = async () => {
      if (!(await checkFormValidity())) return;

      try {
        await saveTransaction(contex.refs.tranDataRef.form);
        toast({
          component: ToastificationContent,
          props: {
            title: isNew.value ? "Сделка добавлена" : "Сделка изменена",
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
        store.commit("investments/SET_UPDATE_PORTFOLIO_DATA");
        contex.root.$nextTick(() => {
          contex.root.$bvModal.hide("modal-transaction");
        });
      } catch (err) {
        // contex.refs.tranFormRef.setErrors(err.response.data);
        console.error(err);
        throw err;
      }
    };
    const handleOk = (bvModalEvt) => {
      bvModalEvt.preventDefault();
      handleSubmit();
    };

    return {
      title,
      okTitle,
      handleOk,
      handleSubmit,
      isNew,
    };
  },
};
</script>

<style></style>
