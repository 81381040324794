export default class Transaction {
  constructor({ portfolioId }) {
    this.id = null;
    this.portfolioId = portfolioId;
    this.securityGroup = "shares";
    this.operationCode = null;
    this.date = null;
    this.securityBoardId = null;
    this.securityName = null;
    this.quantity = null;
    this.price = null;
    this.faceValue = null;
    this.nkd = null;
    this.tax = null;
    this.commission = null;
    this.currencyCode = "RUB";
    this.currencyFromCode = null;
    this.currencyFromSum = null;
    this.moneySum = null;
    this.onOneVar = true;
    this.note = null;
    this.confirmed = false;
    this.createdAt = null;
    this.updatedAt = null;
  }
}
