import { computed } from "@vue/composition-api";
import store from "@/store";

export default function usePortfolio() {
  const portfolios = computed(() => store.getters["investments/portfoliosList"]);
  const activePortfolios = computed(() => portfolios.value.filter((portf) => !portf.isClosed));
  const currentPortfolio = computed(() => store.getters["investments/curPortfolio"]);
  const changePortfolio = (id) => store.commit("investments/SET_PORTFOLIO", id);
  const updateData = computed(() => store.getters["investments/updatePortfolioData"]);
  const currentCurrency = computed(() => store.getters["investments/curCurrency"]);
  const selectedSecurityDetail = computed(() => store.getters["investments/curSecurityDetail"]);

  return {
    portfolios,
    activePortfolios,
    currentPortfolio,
    changePortfolio,
    updateData,
    currentCurrency,
    selectedSecurityDetail,
  };
}
