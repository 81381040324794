<template>
  <div>
    <router-view />
    <transaction-modal />
  </div>
</template>

<script>
import TransactionModal from "@/components/investments/transactions/TransactionModal.vue";

export default {
  name: "LayoutInvestments",
  components: {
    TransactionModal,
  },
};
</script>

<style></style>
