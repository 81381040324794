<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules" #default="{ errors }">
    <v-select
      v-bind="$attrs"
      v-model="innerValue"
      @option:selected="selected"
      :state="errors.length > 0 ? false : null"
    >
      <slot></slot>
      <template #header>
        <slot name="header"></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
      <template #list-header>
        <slot name="list-header"></slot>
      </template>
      <template #list-footer>
        <slot name="list-footer"></slot>
      </template>
    </v-select>
    <field-error-text>{{ errors[0] }}</field-error-text>
  </ValidationProvider>
</template>

<script>
import vSelect from 'vue-select';
import { ValidationProvider } from 'vee-validate';
import FieldErrorText from '@/components/inputs/FieldErrorText.vue';

export default {
  name: 'SelectWithValidation',
  components: {
    vSelect,
    ValidationProvider,
    FieldErrorText,
  },
  props: {
    rules: {
      type: String,
      default: '',
    },
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: null,
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  methods: {
    selected() {
      this.$emit('option:selected', this.innerValue);
    },
  },
  created() {
    if (this.value) this.innerValue = this.value;
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
