<template>
  <b-row>
    <!-- security group -->
    <b-col cols="12">
      <b-form-group label="Вид актива" label-for="securityGroup">
        <select-with-validation
          id="securityGroup"
          v-model="form.securityGroup"
          vid="securityGroup"
          :options="$store.getters['investments/securityGroupList']"
          label="name"
          :reduce="(securityGroup) => securityGroup.value"
          rules="required"
          :clearable="false"
          :searchable="false"
          class="select-size-sm"
          @option:selected="setOperations"
        />
      </b-form-group>
    </b-col>

    <!-- operation -->
    <b-col cols="12">
      <b-form-group label="Операция" label-for="operation">
        <select-with-validation
          id="operation"
          v-model="form.operationCode"
          vid="operation"
          label="name"
          :options="operations"
          :reduce="(operation) => operation.value"
          rules="required"
          :clearable="false"
          :searchable="false"
          class="select-size-sm"
          @option:selected="setVisibleFieldsAndRules"
        />
      </b-form-group>
    </b-col>

    <!-- date -->
    <b-col cols="12">
      <b-form-group label="Дата" label-for="date">
        <date-with-validation
          id="date"
          v-model="form.date"
          vid="date"
          label="Дата"
          size="sm"
          rules="required"
        />
      </b-form-group>
    </b-col>

    <!-- security -->
    <b-col cols="12" v-if="formFields.securityName.visible">
      <b-form-group
        :label="formFields.securityName.label"
        label-for="securityName"
        :description="lotDescription"
      >
        <security-with-validation
          id="securityName"
          v-model="secQuery"
          :rules="formFields.securityName.rules"
          vid="securityName"
          :label="formFields.securityName.label"
          :suggestions="securityList"
          @selected="selectSecurity"
          @input="getList"
          :label_name="`name`"
        />
      </b-form-group>
    </b-col>

    <!-- face value -->
    <b-col cols="12" v-if="formFields.faceValue.visible">
      <b-form-group :label="formFields.faceValue.label" label-for="faceValue">
        <input-with-validation
          id="faceValue"
          v-model="form.faceValue"
          :rules="formFields.faceValue.rules"
          vid="faceValue"
          :label="formFields.faceValue.label"
          size="sm"
          @input="calc"
        />
      </b-form-group>
    </b-col>

    <!-- quantity -->
    <b-col cols="12" v-if="formFields.quantity.visible">
      <b-form-group
        :label="formFields.quantity.label"
        label-for="quantity"
        :description="quantityInPortfolio"
      >
        <input-with-validation
          id="quantity"
          v-model="form.quantity"
          :rules="formFields.quantity.rules"
          vid="quantity"
          :label="formFields.quantity.label"
          size="sm"
          @input="calc"
        />
      </b-form-group>
    </b-col>

    <!-- price -->
    <b-col cols="12" v-if="formFields.price.visible">
      <b-row>
        <b-col cols="9">
          <b-form-group :label="formFields.price.label" label-for="price">
            <input-with-validation
              id="price"
              v-model="form.price"
              :rules="formFields.price.rules"
              vid="price"
              :label="formFields.price.label"
              size="sm"
              :formatter="priceFormatter"
              @input="calc"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="pl-0">
          <b-form-group label="Валюта" label-for="currency">
            <select-with-validation
              id="currency"
              v-model="form.currencyCode"
              vid="currencyCode"
              label="symbol"
              :options="$store.getters['investments/currencyList']"
              :reduce="(currency) => currency.value"
              rules="required"
              :clearable="false"
              :searchable="false"
              class="select-size-sm"
              @input="calc"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>

    <!-- currencyFromSum -->
    <b-col cols="12" v-if="formFields.currencyFromSum.visible">
      <b-row>
        <b-col cols="9">
          <b-form-group :label="formFields.currencyFromSum.label" label-for="currencyFromSum">
            <input-with-validation
              id="currencyFromSum"
              v-model="form.currencyFromSum"
              :rules="formFields.currencyFromSum.rules"
              vid="currencyFromSum"
              :label="formFields.currencyFromSum.label"
              size="sm"
              :formatter="decimalFormatter"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="pl-0">
          <b-form-group label="Валюта" label-for="currencyFrom">
            <select-with-validation
              id="currencyFrom"
              v-model="form.currencyFromCode"
              vid="currencyFromCode"
              label="symbol"
              :options="$store.getters['investments/currencyList']"
              :reduce="(currency) => currency.value"
              rules="required"
              :clearable="false"
              :searchable="false"
              class="select-size-sm"
              @input="calc"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>

    <!-- moneySum -->
    <b-col cols="12" v-if="formFields.moneySum.visible">
      <b-row>
        <b-col cols="9">
          <b-form-group :label="formFields.moneySum.label" label-for="moneySum">
            <input-with-validation
              id="moneySum"
              v-model="form.moneySum"
              :rules="formFields.moneySum.rules"
              vid="moneySum"
              :label="formFields.moneySum.label"
              size="sm"
              :formatter="decimalFormatter"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" class="pl-0">
          <b-form-group label="Валюта" label-for="currencyMoney">
            <select-with-validation
              id="currencyMoney"
              v-model="form.currencyCode"
              vid="currencyCode"
              label="symbol"
              :options="$store.getters['investments/currencyList']"
              :reduce="(currency) => currency.value"
              rules="required"
              :clearable="false"
              :searchable="false"
              class="select-size-sm"
              @input="calc"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>

    <!-- onOneVar -->
    <b-col cols="12" v-if="formFields.onOneVar.visible">
      <b-form-group>
        <b-form-checkbox
          id="onOneVar"
          v-model="form.onOneVar"
          :rules="formFields.onOneVar.rules"
          vid="onOneVar"
          @input="calc"
        >
          {{ formFields.onOneVar.label }}
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- nkd -->
    <b-col cols="12" v-if="formFields.nkd.visible">
      <b-form-group :label="formFields.nkd.label" label-for="nkd">
        <input-with-validation
          id="nkd"
          v-model="form.nkd"
          :rules="formFields.nkd.rules"
          vid="nkd"
          :label="formFields.nkd.label"
          size="sm"
          :formatter="decimalFormatter"
          @input="calc"
        />
      </b-form-group>
    </b-col>

    <!-- tax -->
    <b-col cols="12" v-if="formFields.tax.visible">
      <b-form-group :label="formFields.tax.label" label-for="tax">
        <input-with-validation
          id="tax"
          v-model="form.tax"
          :rules="formFields.tax.rules"
          vid="tax"
          :label="formFields.tax.label"
          size="sm"
          :formatter="decimalFormatter"
        />
      </b-form-group>
    </b-col>

    <!-- commission -->
    <b-col cols="12" v-if="formFields.commission.visible">
      <b-form-group :label="formFields.commission.label" label-for="commission">
        <input-with-validation
          id="commission"
          v-model="form.commission"
          :rules="formFields.commission.rules"
          vid="commission"
          :label="formFields.commission.label"
          size="sm"
          :formatter="decimalFormatter"
        />
      </b-form-group>
    </b-col>

    <!-- note -->
    <b-col cols="12">
      <b-form-group label="Примечание" label-for="note">
        <b-form-input id="note" v-model="form.note" size="sm" />
      </b-form-group>
    </b-col>

    <!-- confirmed -->
    <b-col cols="12">
      <b-form-group>
        <b-form-checkbox id="confirmed" v-model="form.confirmed">
          Сверено с отчётом
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- total sum -->
    <strong class="pl-1"
      >Сумма сделки: {{ totalSum | currency(form.currencyCode || "RUB") }}</strong
    >

    <!-- transaction dates -->
    <b-col cols="12" class="pt-1" v-if="form.createdAt">
      <small class="text-muted font-weight-normal"
        >Создано: {{ new Date(form.createdAt).toLocaleString() }}</small
      >
      <br />
      <small class="text-muted font-weight-normal"
        >Обновлено: {{ new Date(form.updatedAt).toLocaleString() }}</small
      >
    </b-col>
  </b-row>
</template>

<script>
import { ref, reactive, onMounted, computed, watch } from "@vue/composition-api";
import { useDebounceFn, debouncedWatch } from "@vueuse/core";
// @ts-ignore
// eslint-disable-next-line no-unused-vars
import { required, double, integer } from "@validations";
import store from "@/store";

import SelectWithValidation from "@/components/inputs/SelectWithValidation.vue";
import DateWithValidation from "@/components/inputs/DateWithValidation.vue";
import InputWithValidation from "@/components/inputs/InputWithValidation.vue";
import SecurityWithValidation from "@/components/inputs/SecurityWithValidation.vue";

import { secSearch, securityInPortfolio, getPrice } from "@/api/investments";
import usePortfolio from "@/comp-functions/usePortfolio";

import Transaction from "@/models/TransactionModel";

export default {
  name: "TransactionForm",
  components: {
    SelectWithValidation,
    DateWithValidation,
    InputWithValidation,
    SecurityWithValidation,
  },
  filters: {
    currency(value, currency) {
      let newValue = value;
      if (!value) newValue = 0;
      return newValue.toLocaleString("ru-RU", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency,
      });
    },
  },
  setup() {
    let mounted = false;
    let newTransactions = true;
    const { currentPortfolio } = usePortfolio();
    const form = reactive(new Transaction({ portfolioId: currentPortfolio.value.id }));
    const operations = ref([]);
    const formFields = reactive({
      securityName: { label: "Ценная бумага", visible: true, rules: "" },
      faceValue: { label: "Номинал", visible: true, rules: "" },
      quantity: { label: "Количество", visible: true, rules: "" },
      price: { label: "Цена", visible: true, rules: "" },
      nkd: { label: "НКД", visible: true, rules: "" },
      tax: { label: "Налог", visible: true, rules: "" },
      commission: { label: "Комиссия", visible: true, rules: "" },
      currencyFromCode: { label: "", visible: true, rules: "" },
      currencyFromSum: { label: "Продать", visible: true, rules: "" },
      moneySum: { label: "Сумма", visible: true, rules: "" },
      onOneVar: { label: "Начисление на одну бумагу", visible: true, rules: "" },
    });

    const setVisibleFieldsAndRules = (val, securityGroup = null) => {
      const secGroup = securityGroup || form.securityGroup;
      // Default rules
      formFields.securityName.rules = "";
      formFields.quantity.rules = "integer";
      formFields.price.rules = "double:0";
      formFields.faceValue.rules = "integer";
      formFields.nkd.rules = "double:0";
      formFields.tax.rules = "double:0";
      formFields.commission.rules = "double:0";
      formFields.currencyFromCode.rules = "";
      formFields.currencyFromSum.rules = "double:0";
      formFields.moneySum.rules = "double:0";

      if (secGroup === "money") {
        formFields.securityName.visible = false;
        formFields.quantity.visible = false;
        formFields.price.visible = false;
        formFields.moneySum.visible = true;
        formFields.commission.visible = false;
        formFields.tax.visible = false;
        formFields.faceValue.visible = false;
        formFields.nkd.visible = false;
        formFields.onOneVar.visible = false;
        formFields.currencyFromCode.visible = false;
        formFields.currencyFromSum.visible = false;
        formFields.moneySum.label = "Сумма";
        if (val === "conversion") {
          formFields.currencyFromCode.visible = true;
          formFields.currencyFromSum.visible = true;
          formFields.moneySum.label = "Купить";
          // Rules
          formFields.currencyFromCode.rules = "required";
          formFields.currencyFromSum.rules += "|required";
        }
        // Rules
        formFields.moneySum.rules += "|required";
      } else {
        formFields.securityName.visible = true;
        formFields.quantity.visible = true;
        formFields.price.visible = true;
        formFields.commission.visible = true;
        formFields.tax.visible = false;
        formFields.moneySum.visible = false;
        formFields.currencyFromCode.visible = false;
        formFields.currencyFromSum.visible = false;
        formFields.faceValue.visible = false;
        formFields.nkd.visible = false;
        formFields.onOneVar.visible = false;
        if (val === "buy" || val === "sell") {
          formFields.tax.visible = false;
          if (secGroup === "bonds") {
            formFields.faceValue.visible = true;
            formFields.nkd.visible = true;
            formFields.onOneVar.visible = true;
          }
        } else if (val === "dividend") {
          formFields.onOneVar.visible = true;
          formFields.tax.visible = true;
        } else if (val === "coupon") {
          formFields.onOneVar.visible = true;
          formFields.tax.visible = true;
          formFields.commission.visible = false;
        } else if (val === "amortization") {
          formFields.onOneVar.visible = false;
          formFields.faceValue.visible = true;
          formFields.commission.visible = false;
        } else if (val === "redemption") {
          formFields.faceValue.visible = true;
          formFields.commission.visible = false;
        }
        // Rules
        formFields.securityName.rules = "required";
        formFields.quantity.rules += "|required";
        formFields.price.rules += "|required";
        if (secGroup === "bonds" && val !== "coupon") {
          formFields.faceValue.rules += "|required";
        }
      }
    };
    const setOperations = (val, changeOperation = true) => {
      let operationsList = [];
      if (val === "money") {
        operationsList = [
          { value: "refill", name: "Пополнение" },
          { value: "withdrawal", name: "Вывод" },
          { value: "commission", name: "Комиссия" },
          { value: "tax", name: "Налог" },
          { value: "conversion", name: "Конвертация" },
        ];
      } else if (val === "bonds") {
        operationsList = [
          { value: "buy", name: "Покупка" },
          { value: "sell", name: "Продажа" },
          { value: "coupon", name: "Купон" },
          { value: "amortization", name: "Амортизация" },
          { value: "redemption", name: "Погашение" },
        ];
      } else {
        operationsList = [
          { value: "buy", name: "Покупка" },
          { value: "sell", name: "Продажа" },
          { value: "dividend", name: "Дивиденд" },
        ];
      }
      operations.value = operationsList;
      if (changeOperation) {
        if (!form.operationCode || form.operationCode !== operations.value[0].value) {
          form.operationCode = operations.value[0].value;
        }
      }
      setVisibleFieldsAndRules(form.operationCode, val);
    };
    const securityPrice = ref({
      price: undefined,
      faceValue: undefined,
      nkd: undefined,
    });

    // Security suggest
    const secQntLoSize = ref(0);
    const lotDescription = computed(() =>
      secQntLoSize.value === 0
        ? ""
        : `Указывается в штуках. 1 лот = ${secQntLoSize.value.toLocaleString("ru-RU")} шт.`,
    );

    const secQntInPortfolio = ref(0);
    const quantityInPortfolio = computed(() =>
      secQntInPortfolio.value === 0 ? "" : `Количество в портфеле: ${secQntInPortfolio.value}`,
    );

    const secQuery = ref(null);
    const securityList = ref([]);
    const getList = useDebounceFn(async (text) => {
      secQntLoSize.value = 0;

      if (!form.securityGroup || text === "" || text === undefined) {
        return;
      }

      const data = await secSearch({
        secGroup: form.securityGroup,
        query: text,
      });
      securityList.value = [{ data }];
    }, 700);

    async function getSecQntInPortfolio() {
      const { quantity } = await securityInPortfolio({
        portfolioId: form.portfolioId,
        securityBoardId: form.securityBoardId,
        date: form.date,
      });

      secQntInPortfolio.value = quantity;
    }

    async function getSecurityPrice() {
      securityPrice.value.price = undefined;
      securityPrice.value.faceValue = undefined;
      securityPrice.value.nkd = undefined;

      const { price, faceValue, nkd } = await getPrice({
        securityBoardId: form.securityBoardId,
        date: form.date,
      });

      if (newTransactions) {
        if (form.operationCode === "buy" || form.operationCode === "sell") {
          securityPrice.value.price = price;

          form.price = price;
        }

        if (form.securityGroup === "bonds") {
          securityPrice.value.faceValue = faceValue;
          securityPrice.value.nkd = nkd;

          form.faceValue = faceValue;
          form.nkd = nkd;
        }
      }
    }

    const selectSecurity = (item) => {
      form.securityBoardId = item.id;
      form.currencyCode = item.currencyCode;
      form.securityName = item.name;
      secQntLoSize.value = item.lotSize;
    };

    debouncedWatch(
      () => [form.securityBoardId, form.date],
      () => {
        if (!!form.securityBoardId && !!form.date) {
          getSecQntInPortfolio();
          getSecurityPrice();
        }
      },
      { debounce: 500 },
    );

    const priceFormatter = (val) => val.replace(",", ".");
    const decimalFormatter = (val) => val.replace(",", ".");

    // Calculation
    const brokerCommission = ref(0);
    const calcBrokerCommission = () => {
      const brokerFee = +currentPortfolio.value.commission;
      brokerCommission.value = parseFloat((brokerFee / 100).toFixed(5));
    };
    const calcTax = () => {
      const { operationCode, quantity, price, onOneVar } = form;
      const taxRate = 0.13; // TODO: Сделать автоопределение налоговых ставок

      let tax = null;
      if (operationCode === "coupon" || operationCode === "dividend") {
        if (onOneVar) tax = quantity * price * taxRate;
        else tax = price * taxRate;
      }
      if (tax) {
        if (form.currencyCode === "RUB") tax = Math.round(tax);
        tax = tax.toFixed(2);
      }
      form.tax = tax;
    };
    const calcCommission = () => {
      const { securityGroup: secGroup, operationCode, quantity, price, faceValue } = form;

      let commission = 0.0;
      if (secGroup === "shares" || secGroup === "etfs") {
        if (operationCode !== "dividend") commission = quantity * price * brokerCommission.value;
      } else if (secGroup === "bonds") {
        const bondPrice = (price * faceValue) / 100;
        if (operationCode === "buy" || operationCode === "sell") {
          commission = bondPrice * quantity * brokerCommission.value;
        }
      }
      form.commission = commission.toFixed(2);
    };
    const calc = () => {
      if (mounted) {
        calcTax();
        calcCommission();
      }
    };
    const calcTotalSum = () => {
      let {
        // eslint-disable-next-line prefer-const
        securityGroup: secGroup,
        // eslint-disable-next-line prefer-const
        operationCode: operation,
        quantity,
        price,
        // eslint-disable-next-line prefer-const
        onOneVar,
        tax,
        commission,
        nkd,
        faceValue,
        moneySum,
      } = form;

      // FIXME
      quantity = +quantity;
      price = +price;
      tax = +tax;
      commission = +commission;
      nkd = +nkd;
      faceValue = +faceValue;
      moneySum = +moneySum;

      let sum = 0;
      if (secGroup === "shares" || secGroup === "etfs") {
        if (operation === "buy") sum = quantity * price + commission;
        else if (operation === "sell") sum = quantity * price - commission;
        else if (operation === "dividend") {
          if (onOneVar) sum = quantity * price - tax - commission;
          else sum = price - tax - commission;
        }
      } else if (secGroup === "bonds") {
        const bondPrice = (price * faceValue) / 100;
        if (operation === "buy") {
          if (onOneVar) sum = (bondPrice + nkd) * quantity + commission;
          else sum = bondPrice * quantity + nkd + commission;
        } else if (operation === "sell") {
          if (onOneVar) sum = (bondPrice + nkd) * quantity - commission;
          else sum = bondPrice * quantity + nkd - commission;
        } else if (operation === "coupon") {
          if (onOneVar) sum = quantity * price - tax;
          else sum = price - tax;
        } else if (operation === "amortization" || operation === "redemption") {
          sum = bondPrice * quantity;
        }
      } else if (secGroup === "money") sum = moneySum;

      if (!sum) sum = 0;
      return parseFloat(sum.toFixed(2));
    };
    const totalSum = computed(() => calcTotalSum());

    //
    onMounted(async () => {
      calcBrokerCommission();
      const transactionId = store.getters["investments/curTransaction"];
      newTransactions = !transactionId;
      if (transactionId) {
        const response = await store.dispatch("investments/getTransactionDetail", transactionId);

        if (response) {
          Object.keys(form).forEach((key) => (form[key] = response[key]));
          secQuery.value = response.securityName;

          if (form.commission) form.commission = parseFloat(form.commission).toFixed(2);
          if (form.tax) form.tax = parseFloat(form.tax).toFixed(2);
          if (form.nkd) form.nkd = parseFloat(form.nkd).toFixed(2);
          if (form.price) form.price = parseFloat(form.price).toFixed(5);
          if (form.moneySum) form.moneySum = parseFloat(form.moneySum).toFixed(2);
        }
      }
      await setOperations(form.securityGroup, !transactionId);
      mounted = true;
    });

    return {
      form,
      formFields,
      operations,
      setVisibleFieldsAndRules,
      setOperations,

      secQuery,
      selectSecurity,
      getList,
      securityList,

      lotDescription,
      quantityInPortfolio,
      securityPrice,

      priceFormatter,
      decimalFormatter,

      calc,
      totalSum,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
