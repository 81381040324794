<template>
  <ValidationProvider :name="$attrs.label" :vid="$attrs.vid" :rules="rules" #default="{ errors }">
    <vue-autosuggest
      v-model="innerValue"
      :input-props="inputProps"
      :limit="10"
      @input="onInputChange"
      @focus="focusInput"
      :suggestions="$attrs.suggestions"
      @selected="selectSecurity"
      :get-suggestion-value="getSuggestionValue"
      :state="errors.length > 0 ? false : null"
    >
      <template slot-scope="{ suggestion }">
        <span class="my-suggestion-item">{{ suggestion.item.label }}</span>
      </template>
    </vue-autosuggest>
    <field-error-text>{{ errors[0] }}</field-error-text>
  </ValidationProvider>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import FieldErrorText from "@/components/inputs/FieldErrorText.vue";

export default {
  name: "SecurityWithValidation",
  props: {
    field_id: {
      type: String,
      default: "security",
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: null,
    },
    label_name: {
      type: String,
      required: true,
    },
  },
  components: {
    VueAutosuggest,
    ValidationProvider,
    FieldErrorText,
  },
  data: () => ({
    innerValue: null,
    inner_field_id: "",
    inputProps: {
      id: "",
      class: "form-control form-control-sm",
      name: "security",
    },
  }),
  watch: {
    inner_field_id(newVal) {
      this.inputProps.id = newVal;
    },
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  methods: {
    onInputChange(text) {
      this.$emit("input", text);
    },
    focusInput(text) {
      this.$emit("focus", text);
    },
    selectSecurity(item) {
      this.$emit("selected", item.item);
    },
    getSuggestionValue(suggestion) {
      return suggestion.item[this.label_name];
    },
  },
  created() {
    if (this.field_id) this.inner_field_id = this.field_id;
    if (this.value) this.innerValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
